import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import ElectricRatesIllustration from "../../../svg/ComparisonResultPageIllustrations/electricityrates.svg";
import LoneStarSVG from "../../../svg/ComparisonResultGridLogos/electricrates/lonestar.svg";
import NewPowerSVG from "../../../svg/ComparisonResultGridLogos/electricrates/newpower.svg";
import PowerNextSVG from "../../../svg/ComparisonResultGridLogos/electricrates/powernext.svg";
import PulsePowerSVG from "../../../svg/ComparisonResultGridLogos/electricrates/pulsepower.svg";
import XoomSVG from "../../../svg/ComparisonResultGridLogos/electricrates/xoom.svg";
export const pageMetaData = {
  pageTitle: "Compare Electricity Rates",
  pageDescription: "Monthly Deals From $49.95",
  pageImagePath: "/gasrates.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Monthly Payment"
      }, {
        index: 2,
        text: "Minimum Term"
      }, {
        index: 3,
        text: "Alternative Terms Available"
      }, {
        index: 4,
        text: "Type Of Product"
      }, {
        index: 5,
        text: "Termination Fees"
      }, {
        index: 6,
        text: "Is Price Locked"
      }, {
        index: 7,
        text: "Renewable %"
      }],
      expansionRows: [{
        index: 8,
        text: "500 kwh p/m Montly Price"
      }, {
        index: 9,
        text: "1000 kwh p/m Montly Price"
      }, {
        index: 10,
        text: "2000 kwh p/m Montly Price"
      }, {
        index: 11,
        text: "Refund Policy"
      }]
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Pulse Power",
          subText: "",
          imageKey: "pulse-power"
        }
      }, {
        index: 2,
        text: "57.45",
        isPriceCell: true,
        showSavingText: false,
        savingText: "149",
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "12 Months",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 4,
        text: "24 & 36 Months",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 5,
        text: "Fixed Rate",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 6,
        text: "$20 per remaining contract month",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 7,
        text: "",
        useIcon: true,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 8,
        text: "19%",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }],
      expansionRows: [{
        index: 9,
        text: "$57.45",
        useIcon: false
      }, {
        index: 10,
        text: "$106.35",
        useIcon: false
      }, {
        index: 11,
        text: "$202.95",
        useIcon: false
      }, {
        index: 12,
        text: "Customer Balance Credit",
        useIcon: false
      }],
      claimButtonConfiguration: {
        targetUrl: "https://www.pulsepowertexas.com/enrollment",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Pulse Power",
        subText: "",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Xoom Energy",
          subText: "",
          imageKey: "xoom-energy"
        }
      }, {
        index: 2,
        text: "65.49",
        isPriceCell: true,
        showSavingText: false,
        savingText: "149",
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "12 Months",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 4,
        text: "24 & 36 Months",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 5,
        text: "Fixed Rate",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 6,
        text: "$150",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 7,
        text: "",
        useIcon: true,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 8,
        text: "12%",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }],
      expansionRows: [{
        index: 9,
        text: "$65.49",
        useIcon: false
      }, {
        index: 10,
        text: "$117.99",
        useIcon: false
      }, {
        index: 11,
        text: "$220.99",
        useIcon: false
      }, {
        index: 12,
        text: "Deposit returned after 12 on-time payments",
        useIcon: false
      }],
      claimButtonConfiguration: {
        targetUrl: "https://texas.xoomenergy.com/en/res/step-0/esiid",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Xoom Energy",
        subText: "",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "New Power",
          subText: "",
          imageKey: "new-power"
        }
      }, {
        index: 2,
        text: "49.95",
        isPriceCell: true,
        showSavingText: false,
        savingText: "149",
        showRating: true,
        rating: 5
      }, {
        index: 3,
        text: "12 Months",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 4,
        text: "24 & 36 Months",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 5,
        text: "Fixed Rate",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 6,
        text: "$20 per remaining contract month",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 7,
        text: "",
        useIcon: true,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 8,
        text: "19%",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }],
      expansionRows: [{
        index: 9,
        text: "$49.95",
        useIcon: false
      }, {
        index: 10,
        text: "$90.95",
        useIcon: false
      }, {
        index: 11,
        text: "$172.95",
        useIcon: false
      }, {
        index: 12,
        text: "Customer Balance Credit",
        useIcon: false
      }],
      claimButtonConfiguration: {
        targetUrl: "https://www.newpowertx.com/enrollment",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Pulse Power",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Power Next",
          subText: "",
          imageKey: "power-next"
        }
      }, {
        index: 2,
        text: "51.45",
        isPriceCell: true,
        showSavingText: false,
        savingText: "149",
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "12 Months",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 4,
        text: "24 & 36 Months",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 5,
        text: "Fixed Rate",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 6,
        text: "$20 per remaining contract month",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 7,
        text: "",
        useIcon: true,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 8,
        text: "19%",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }],
      expansionRows: [{
        index: 9,
        text: "$51.45",
        useIcon: false
      }, {
        index: 10,
        text: "$92.95",
        useIcon: false
      }, {
        index: 11,
        text: "$174.95",
        useIcon: false
      }, {
        index: 12,
        text: "Customer Balance Credit",
        useIcon: false
      }],
      claimButtonConfiguration: {
        targetUrl: "https://www.mypowernext.com/enrollment",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Power Next",
        subText: "",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Lone Star Energy",
          subText: "",
          imageKey: "lone-star"
        }
      }, {
        index: 2,
        text: "57.45",
        isPriceCell: true,
        showSavingText: false,
        savingText: "149",
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "12 Months",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 4,
        text: "24 & 36 Months",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 5,
        text: "Fixed Rate",
        isPriceCell: false,
        showSavingText: false,
        showRating: false,
        useIcon: false
      }, {
        index: 6,
        text: "$20 per remaining contract month",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 7,
        text: "",
        useIcon: true,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }, {
        index: 8,
        text: "19%",
        useIcon: false,
        icon: "cross",
        isPriceCell: false,
        showSavingText: false,
        showRating: false
      }],
      expansionRows: [{
        index: 9,
        text: "$57.45",
        useIcon: false
      }, {
        index: 10,
        text: "$104.95",
        useIcon: false
      }, {
        index: 11,
        text: "$198.95",
        useIcon: false
      }, {
        index: 12,
        text: "Customer Balance Credit",
        useIcon: false
      }],
      claimButtonConfiguration: {
        targetUrl: "https://www.lonestarenergytx.com/enrollment?",
        openLinkExternally: true,
        text: "Sign Up",
        subText: "",
        title: "Click to sign up to Lone Star Power",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Comparing electricity rates`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<ElectricRatesIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="ElectricRatesIllustration" />, <ElectricRatesIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="ElectricRatesIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`All of us need energy, but are you getting the best possible deal for your location? Low rates are essential, but other things need to go into your decision, too. What's customer service like? Is there a plan that fits your needs? Will your rates constantly change?`}</p>
        <p>{`There are plenty of power providers in Texas because of the deregulated market. We've done the work to identify the top 5 electrical services so you can make an informed choice.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Electricity Rate Deals" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <LoneStarSVG imagekey="lone-star" mdxType="LoneStarSVG" />
  <NewPowerSVG imagekey="new-power" mdxType="NewPowerSVG" />
  <PowerNextSVG imagekey="power-next" mdxType="PowerNextSVG" />
  <PulsePowerSVG imagekey="pulse-power" mdxType="PulsePowerSVG" />
  <XoomSVG imagekey="xoom-energy" mdxType="XoomSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="utilities" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h3>{`How We Ranked Energy Providers`}</h3>
        <p>{`It's easy to jump online and find a simple comparison of rates by number, but a good comparison service should allow you to do more.`}</p>
        <p>{`High deposits, poor customer service, rate instability, or simply a lack of options that makes sense for your needs can make a low rate a very poor deal in the end. Here's why we ranked providers the way we did:`}</p>
        <h3>{`Low Rates`}</h3>
        <p>{`You'll notice that our highest rated choices don't have the lowest possible rates. We looked for low rates, of course, and New Energy has just about the best you'll find from a reliable and established service.`}</p>
        <p>{`But if you're looking for green energy, or if simplicity and good customer service from a company that has been around for a while are worth it to you, some of our top-rated services are worth checking out even if their rates aren't the lowest available.`}</p>
        <h3>{`Customer Service`}</h3>
        <p>{`Nothing is more aggravating than not being able to work out problems with your electricity provider, and that's one reason we ranked Pulse Energy in the top five. While they don't have the best rates, they do make the whole process very smooth and offer excellent customer service.`}</p>
        <p>{`Meanwhile, though Xoom Energy has a lot going for it and is worth considering, their customer service is not a strong point.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="electricity rates" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`At `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{`, we want to take the hassle out of taking charge of your budget. There are a lot of choices out there; let us help you make the right one.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>


    <p>{`;`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      